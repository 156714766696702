import { Stack, Typography } from "@mui/material";
import { ReactElement } from "react";

interface Props {
  children: ReactElement | null;
}

export default function Footer(props: Props) {
  return (
    <Stack direction="column" gap={3}>
      {props.children}
      <Typography variant="subtitle1" textAlign="center">
        Powered by Stitch
      </Typography>
    </Stack>
  );
}
