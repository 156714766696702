import { Stack, Typography } from "@mui/material";
import { ReactElement } from "react";

type Props = {
  label: string;
  content: ReactElement | null;
};

export default function CreditApplicationRow(props: Props) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing="1"
      minHeight="76px"
    >
      <Typography>{props.label}</Typography>
      {props.content}
    </Stack>
  );
}
