import { Stack, Typography } from "@mui/material";
import { FadeInStack } from "@pd/components/FadeComponents";
import StitchErrorIcon from "@pd/components/StitchErrorIcon";
import { useAppSelector } from "@pd/redux/store";
import { selectMerchantName } from "../../redux/selectors/auth";

export default function Error() {
  const merchantName = useAppSelector(selectMerchantName);

  return (
    <FadeInStack
      alignItems="center"
      justifyContent="center"
      sx={{ height: "calc(100vh - 90px)", p: 3 }}
      gap={3}
    >
      <StitchErrorIcon />
      <Stack gap={2} alignItems="center">
        <Typography variant="h1">Error</Typography>
        <Typography variant="body1" sx={{ mt: 6 }} align="center">
          Unable to find credit application invitation, please reach out to{" "}
          {merchantName} for a new link.
        </Typography>
      </Stack>
    </FadeInStack>
  );
}
