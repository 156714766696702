import ErrorBoundary from "@pd/components/ErrorBoundary";
import BuyerCredit from ".";
import InvitationGuard from "./components/InvitationGuard";
import CreditApplication from "./pages/CreditApplication";
import LinkBank from "./pages/LinkBank";
import Submitted from "./pages/Submitted";
import Error from "./pages/Error";

export default [
  {
    path: "/portal/application",
    element: <BuyerCredit />,
    errorElement: <ErrorBoundary fallback />,
    children: [
      {
        path: "/portal/application",
        element: (
          <InvitationGuard>
            <CreditApplication />
          </InvitationGuard>
        ),
        errorElement: <ErrorBoundary fallback />,
      },
      {
        path: "/portal/application/link",
        element: (
          <InvitationGuard>
            <LinkBank />
          </InvitationGuard>
        ),
        errorElement: <ErrorBoundary fallback />,
      },
      {
        path: "/portal/application/submitted",
        element: (
          <InvitationGuard>
            <Submitted />
          </InvitationGuard>
        ),
        errorElement: <ErrorBoundary fallback />,
      },
      {
        path: "/portal/application/error",
        element: <Error />,
        errorElement: <ErrorBoundary fallback />,
      },
    ],
  },
];
