/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector } from "@reduxjs/toolkit";
import { isBuyerCredit } from "@pd/layouts/BuyerCredit/redux/selectors";
import { jwtDecode } from "jwt-decode";

// eslint-disable-next-line import/prefer-default-export
export const selectMerchantName = createSelector(
  (state: any) => state,
  (state: any): string => {
    if (isBuyerCredit(state)) {
      try {
        const decodedToken: { name: string } = jwtDecode(
          state.startup.auth.data.jwt,
        );
        const name = decodedToken?.name || "merchant";
        return name;
      } catch (e) {
        return "merchant";
      }
    }
    return "merchant";
  },
);
