import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { MANUAL_ERROR_CODE } from "@pd/utils/constants";
import {
  GetCreditApplicationResType,
  UpdateCreditApplicationResType,
  fetchGetCreditApplication,
  fetchSubmitCreditApplication,
  fetchUpdateCreditApplication,
} from "@pd/api/buyerCredit";
import { CreditApplicationStateType } from "@pd/layouts/BuyerCredit/types";
import ca from "@pd/layouts/BuyerCredit/redux/actions/creditApplication";
import { selectCreditApplication } from "@pd/layouts/BuyerCredit/redux/selectors/creditApplication";
import routeActions from "@pd/layouts/BuyerCredit/redux/actions/routes";
import { addressDbToAddressRequest } from "@pd/utils/address";
import { selectJwt } from "@pd/redux/selectors/auth";

export default function* creditApplicationSaga() {
  yield all([
    takeLatest(ca.fetchCreditApplication, onFetchCreditApplication),
    takeLatest(ca.updateCreditApplication, onUpdateCreditApplication),
    takeLatest(ca.submitCreditApplication, onSubmitCreditApplication),
  ]);
}

export function* onFetchCreditApplication() {
  try {
    yield all([
      put(ca.apiError({ message: "", status: 0 })),
      put(ca.apiFetching(true)),
      put(ca.apiSuccess(false)),
    ]);
    const jwt: string = yield select(selectJwt);
    const res: GetCreditApplicationResType = yield call(
      fetchGetCreditApplication,
      jwt,
    );
    if ("error" in res) {
      if (res.error.status === 401) {
        yield all([
          put(ca.apiError(res.error)),
          put(ca.apiFetching(false)),
          put(routeActions.redirect("/portal/application/error")),
        ]);
      } else if (res.error.status === 410) {
        // "Error: This application has already been submitted."
        yield all([
          put(ca.apiError(res.error)),
          put(ca.apiFetching(false)),
          put(routeActions.redirect("/portal/application/submitted")),
        ]);
      } else {
        yield all([put(ca.apiError(res.error)), put(ca.apiFetching(false))]);
      }
    } else {
      yield all([
        put(ca.hydrateCreditApplication(res)),
        put(ca.apiSuccess(true)),
        put(ca.apiFetching(false)),
      ]);
      if (res.data.status === "pending" || res.data.status === "complete") {
        yield put(routeActions.redirect("/portal/application/submitted"));
      }
    }
  } catch (error) {
    const errMsg =
      "An error occured while fetching your business information. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        ca.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(ca.apiFetching(false)),
    ]);
  }
}

export function* onUpdateCreditApplication() {
  try {
    yield all([
      put(ca.apiError({ message: "", status: 0 })),
      put(ca.apiFetching(true)),
      put(ca.apiSuccess(false)),
    ]);
    const jwt: string = yield select(selectJwt);
    const creditApplication: CreditApplicationStateType = yield select(
      selectCreditApplication,
    );
    const res: UpdateCreditApplicationResType = yield call(
      fetchUpdateCreditApplication,
      jwt,
      {
        appCompanyName: creditApplication.data.businessName,
        appTaxId: creditApplication.data.ein,
        appWebsite: creditApplication.data.website,
        appAddress: addressDbToAddressRequest(creditApplication.data.address),
      },
    );
    if ("error" in res) {
      if (res.error.status === 401) {
        yield all([
          put(ca.apiError(res.error)),
          put(ca.apiFetching(false)),
          put(routeActions.redirect("/portal/application/error")),
        ]);
      } else {
        yield all([put(ca.apiError(res.error)), put(ca.apiFetching(false))]);
      }
    } else {
      yield all([
        put(ca.hydrateCreditApplication(res)),
        put(ca.apiSuccess(true)),
        put(ca.apiFetching(false)),
        put(routeActions.redirect("/portal/application/link")),
      ]);
    }
  } catch (error) {
    const errMsg =
      "An error occured while updating your business information. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        ca.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(ca.apiFetching(false)),
    ]);
  }
}

export function* onSubmitCreditApplication(
  action: ReturnType<typeof ca.submitCreditApplication>,
) {
  try {
    yield all([
      put(ca.apiError({ message: "", status: 0 })),
      put(ca.apiFetching(true)),
    ]);
    const jwt: string = yield select(selectJwt);

    const res: UpdateCreditApplicationResType = yield call(
      fetchSubmitCreditApplication,
      jwt,
      {
        publicToken: action.payload.publicToken,
        ids: action.payload.accountIds,
      },
    );
    if ("error" in res) {
      if (res.error.status === 401) {
        yield all([
          put(ca.apiError(res.error)),
          put(ca.apiFetching(false)),
          put(routeActions.redirect("/portal/application/error")),
        ]);
      } else {
        yield all([put(ca.apiError(res.error)), put(ca.apiFetching(false))]);
      }
    } else {
      const isBankLinked = [
        action.payload.publicToken,
        action.payload.accountIds && action.payload.accountIds.length > 0,
      ].every(Boolean);
      yield all([
        put(ca.apiSuccess(true)),
        put(ca.apiFetching(false)),
        put(ca.setIsBankLinked(isBankLinked)),
        put(routeActions.redirect("/portal/application/submitted")),
      ]);
    }
  } catch (error) {
    const errMsg =
      "An error occured while submitting your business information. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        ca.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(ca.apiFetching(false)),
    ]);
  }
}
