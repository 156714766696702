/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from "react";
import {
  Stack,
  Typography,
  Button,
  Grid,
  Card,
  Snackbar,
  Alert,
} from "@mui/material";

import tracer from "@pd/tracing";
import { ERROR_BOUNDARY } from "@pd/tracing/constants";
import bgGrid from "@pd/assets/images/maintenance/bg-grid.png";
import svgError from "@pd/assets/svgs/500.svg";

interface Props {
  children?: React.ReactNode;
  fallback?: boolean;
}

interface IError {
  [key: string]: any;
}

interface State {
  showError: boolean;
  showCopied: boolean;
  error: any;
}

export default class ErrorBoundary extends Component<Props, State> {
  static defaultProps = {
    fallback: false,
    children: null,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      showError: false,
      showCopied: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error: any) {
    return { showError: true, error };
  }

  componentDidCatch(error: any) {
    tracer.captureException(error, ERROR_BOUNDARY);
  }

  handleRetry = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (this.state.error) {
      this.setState({ showError: false, error: null });
    } else {
      window.location.reload();
    }
    e.stopPropagation();
  };

  handleCopyErrorInfo = (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      Promise.resolve().then(async () => {
        const errorContent = JSON.stringify(
          this.state.error,
          replaceErrors,
          "  ",
        );
        navigator.clipboard.writeText(errorContent);
        this.setState({ showCopied: true });
      });
    } catch (err) {
      console.error("Failed to copy id: ", err);
    }
    e.preventDefault();
    e.stopPropagation();
  };

  get errorMessage() {
    return this.state.showError ? (
      <Typography
        variant="subtitle2"
        sx={{
          maxWidth: "calc(100vw - 40px)",
          maxHeight: "100px",
          backgroundColor: "white",
          fontFamily: "monospace",
          textAlign: "left",
          overflowX: "scroll",
          p: 2,
          whiteSpace: "nowrap",
          "::-webkit-scrollbar": {
            width: "7px",
            height: "7px",
          },
          "::-webkit-scrollbar-track": {
            background: "#F1F1F1",
          },
          "::-webkit-scrollbar-thumb": {
            background: "#ddd",
          },
          "::-webkit-scrollbar-thumb:hover": {
            background: "#333",
          },
        }}
      >
        <span style={{ fontFamily: "monospace" }}>
          {JSON.stringify(this.state.error, replaceErrors, "  ")}
        </span>
      </Typography>
    ) : (
      <div>
        <button
          type="button"
          onClick={(e) => {
            this.setState({ showError: true });
            e.stopPropagation();
          }}
        >
          Show Error
        </button>
      </div>
    );
  }

  render() {
    if (this.state.error || this.props.fallback) {
      return (
        <>
          <Stack
            spacing={5}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              padding: "20px",
              backgroundColor: "#FFFBE8",
              backgroundImage: `url(${bgGrid})`,
              backgroundSize: "cover",
              minHeight: "100vh",
              height: "100%",
              width: "100vw",
            }}
          >
            <Stack alignItems="center" justifyContent="center">
              <img
                src={svgError}
                alt="caught-error"
                style={{
                  maxWidth: "300px",
                  width: "80%",
                  height: "100%",
                }}
              />
            </Stack>
            <Stack gap={3} justifyContent="center" alignItems="center">
              <Typography
                color="primary"
                align="center"
                variant="h4"
                sx={{ fontFamily: "CircularBold" }}
              >
                Something went wrong.
              </Typography>
              <Typography color="primary" align="center" variant="body1">
                Something went wrong on our end.
                <br />
                Retry or contact us if the problem persists.
              </Typography>
              <Grid
                direction="column"
                container
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Grid
                  item
                  lg={11}
                  alignItems="center"
                  justifyContent="center"
                  style={{ width: "100%", display: "flex" }}
                >
                  <Button
                    variant="contained"
                    onClick={this.handleRetry}
                    style={{ width: "70%", height: "45px" }}
                  >
                    Retry
                  </Button>
                </Grid>
              </Grid>
              <Stack gap={1} alignItems="center" justifyContent="center">
                {this.state.showError && (
                  <Stack gap={2} sx={{ width: "80%" }}>
                    <Typography variant="body2" align="center">
                      If you choose to contact us, please provide the Error
                      information below. Thank you!
                    </Typography>
                    <Button
                      variant="text"
                      onClick={this.handleCopyErrorInfo}
                      style={{
                        backgroundColor: "transparent",
                      }}
                    >
                      <Card
                        style={{
                          padding: "10px",
                          marginLeft: "10px",
                          marginRight: "10px",
                          fontSize: "10px",
                        }}
                      >
                        <code>{this.errorMessage}</code>
                      </Card>
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={this.state.showCopied}
            autoHideDuration={6000}
            onClose={() => this.setState({ showCopied: false })}
            sx={{ mb: 5 }}
          >
            <Alert
              onClose={() => this.setState({ showCopied: false })}
              severity="success"
              variant="filled"
              sx={{
                width: "100%",
                backgroundColor: "#000",
              }}
            >
              Copied Error Info to clipboard!
            </Alert>
          </Snackbar>
        </>
      );
    }

    return this.props.children;
  }
}

function replaceErrors(_: string, value: any) {
  if (value instanceof Error) {
    const error = {} as IError;

    Object.getOwnPropertyNames(value).forEach((k: string) => {
      error[k] = (value as IError)[k];
    });

    return error;
  }

  return value;
}
