import { Stack, Typography } from "@mui/material";
import StringInputSection from "@pd/components/StringInputSection";
import { FadeInStack } from "@pd/components/FadeComponents";
import AddressInputSection, {
  AddressValidationErrors,
} from "@pd/components/AddressInputSection";
import { AddressDbType } from "@pd/redux/types/dbTypes";
import Footer from "@pd/layouts/BuyerCredit/components/Footer";
import {
  validateBusinessName,
  validateEin,
  validateEinRequired,
  validateWebsite,
} from "@pd/utils/validation";
import { useEffect, useState } from "react";
import formatEin from "@pd/utils/formatEin";
import { useAppSelector } from "@pd/redux/store";
import { useDispatch } from "react-redux";
import ca from "@pd/layouts/BuyerCredit/redux/actions/creditApplication";
import {
  selectCreditApplication,
  selectApiError,
  selectApiFetching,
} from "@pd/layouts/BuyerCredit/redux/selectors/creditApplication";
import StatusMessage from "@pd/components/ErrStatusMessage";
import { selectMerchantName } from "@pd/layouts/BuyerCredit/redux/selectors/auth";
import StitchAsyncButton from "@pd/components/StitchAsyncButton";
import { ArrowForward } from "@mui/icons-material";

export default function CreditApplication() {
  const dispatch = useDispatch();

  const apiError = useAppSelector(selectApiError);
  const apiFetching = useAppSelector(selectApiFetching);
  const creditApplication = useAppSelector(selectCreditApplication);
  const merchantName = useAppSelector(selectMerchantName);
  const merchantNamePossessive =
    merchantName.charAt(merchantName.length - 1) === "s"
      ? `${merchantName}'`
      : `${merchantName}'s`;

  const [uiErrors, setUiErrors] = useState({
    businessName: "",
    ein: "",
    website: "",
    street1: "",
    street2: "",
    city: "",
    state: "",
    postalCode: "",
  });

  const hasErrors = [
    uiErrors.businessName,
    uiErrors.ein,
    uiErrors.website,
    uiErrors.street1,
    uiErrors.street2,
    uiErrors.city,
    uiErrors.state,
    uiErrors.postalCode,
  ].some(Boolean);

  const handleValidationResult = (name: string) => (error: string) => {
    if (error) {
      const newUiErrors = { ...uiErrors, [name]: error };
      setUiErrors(newUiErrors);
    } else {
      setUiErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleValidation = (name: string, value: string): boolean => {
    switch (name) {
      case "businessName":
        return validateBusinessName(value, handleValidationResult(name));
      case "ein":
        return validateEin(value, handleValidationResult(name));
      case "website":
        return validateWebsite(value, handleValidationResult(name));
      default:
        return true;
    }
  };

  const validateAll = (): boolean => {
    const allValid = [
      handleValidation("businessName", creditApplication.data.businessName),
      handleValidation("ein", creditApplication.data.ein),
      handleValidation("website", creditApplication.data.website),
      handleValidation("street1", creditApplication.data.address.line1),
      handleValidation("street2", creditApplication.data.address.line2),
      handleValidation("city", creditApplication.data.address.city),
      handleValidation("state", creditApplication.data.address.state),
      handleValidation("postalCode", creditApplication.data.address.postalCode),
    ].every(Boolean);
    return allValid;
  };

  const onContinue = () => {
    const allValid = validateAll();
    if (allValid && !creditApplication.fetching) {
      dispatch(ca.updateCreditApplication());
    }
  };

  const onChange = (
    keyName: string,
    value: string,
    _isValid: boolean,
    originalEvent?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    handleValidation(keyName, value);

    switch (keyName) {
      case "businessName":
        dispatch(ca.setBusinessName(value));
        break;
      case "ein":
        if (
          originalEvent &&
          "inputType" in originalEvent.nativeEvent &&
          originalEvent.nativeEvent.inputType === "deleteContentBackward"
        ) {
          if (value.length === 3 && value.charAt(value.length - 1) === "-") {
            const nextValueSansDash = value.substring(0, 2);
            dispatch(ca.setEin(nextValueSansDash));
            return;
          }
          if (value.length === 2) {
            dispatch(ca.setEin(value.substring(0, 1)));
            return;
          }
        }
        dispatch(ca.setEin(value));
        break;
      case "website":
        dispatch(ca.setWebsite(value));
        break;
      default:
        break;
    }
  };

  const onAddressChange = (value: AddressDbType) => {
    dispatch(ca.setAddress(value));
  };

  const onAddressValidationChange = (
    addressErrors: AddressValidationErrors,
  ) => {
    const newUiErrors = { ...uiErrors };
    newUiErrors.street1 = addressErrors.line1;
    newUiErrors.street2 = addressErrors.line2;
    newUiErrors.city = addressErrors.city;
    newUiErrors.state = addressErrors.state;
    newUiErrors.postalCode = addressErrors.postalCode;
    setUiErrors(newUiErrors);
  };

  if (apiError.status) {
    window.scrollTo(0, document.body.scrollHeight);
  }

  useEffect(() => {
    dispatch(ca.clearAsyncState());
    return () => {
      dispatch(ca.clearAsyncState());
    };
  }, []);

  return (
    <FadeInStack
      spacing={3}
      sx={{
        height: "100%",
        minHeight: "calc(100vh - 50px)",
        maxWidth: "600px",
      }}
    >
      <Stack
        spacing={3}
        sx={{
          flexGrow: 1,
        }}
      >
        <Typography variant="h5">Your business</Typography>
        <Typography variant="h5">
          Share your business information for {merchantNamePossessive} credit
          review.
        </Typography>
        <StringInputSection
          keyName="businessName"
          label="Name"
          placeholder="Enter registered business name"
          required
          value={creditApplication.data.businessName}
          onChange={onChange}
          validator={validateBusinessName}
        />
        <StringInputSection
          keyName="ein"
          label="EIN"
          placeholder="XX-XXXXXXX"
          required
          value={formatEin(creditApplication.data.ein || "")}
          maxChars={10}
          onChange={onChange}
          validator={validateEinRequired}
        />
        <StringInputSection
          keyName="website"
          label="Website"
          placeholder="www.example.com"
          value={creditApplication.data.website}
          onChange={onChange}
          validator={validateWebsite}
        />
        <AddressInputSection
          onAddressChange={onAddressChange}
          onValidationChange={onAddressValidationChange}
          address={creditApplication.data.address}
          required
        />
      </Stack>
      <Footer>
        <>
          <StatusMessage loading={apiFetching} apiError={apiError} />
          <StitchAsyncButton
            buttonText="Continue"
            onClick={onContinue}
            color="primary"
            variant="contained"
            logoColor="black"
            loadingSize="small"
            loading={apiFetching}
            loadingPosition={{
              top: 0,
              left: 0,
            }}
            endAdornment={<ArrowForward />}
            success={false}
            disabled={hasErrors}
          />
        </>
      </Footer>
    </FadeInStack>
  );
}
