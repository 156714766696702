import { Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { selectNextRoute } from "./redux/selectors/routes";
import routeActions from "./redux/actions/routes";

export default function BuyerCredit() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const nextRoute = useAppSelector(selectNextRoute);

  useEffect(() => {
    if (nextRoute) {
      navigate(nextRoute + search);
      dispatch(routeActions.clearNextRoute());
    }
  }, [nextRoute]);

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        padding: 3,
        // TODO(STC-284): Refactor to avoid using vw and vh while inside portalDashboard,
        // to avoid extra horizontal scrollbars when content is scrolled vertically.
        width: "100vw",
      }}
    >
      <Outlet />
    </Stack>
  );
}
