import ErrorBoundary from "@pd/components/ErrorBoundary";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import { ReactElement, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import authActions from "@pd/redux/actions/auth";
import creditApplicationActions from "@pd/layouts/BuyerCredit/redux/actions/creditApplication";
import { selectIsExpired, selectJwt } from "@pd/redux/selectors/auth";

type Props = {
  children: ReactElement | null;
};

function InvitationGuard(props: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const params = new URLSearchParams(location.search);
  const jwtParam = params.get("auth");

  const jwtSelect = useAppSelector(selectJwt);
  const isExpired = useAppSelector(selectIsExpired);

  useEffect(() => {
    if (!jwtSelect && jwtParam != null) {
      dispatch(authActions.setAuthToken(jwtParam));
    } else if (
      [!jwtSelect && jwtParam == null, !!jwtSelect && isExpired].some(Boolean)
    ) {
      navigate("/portal/application/error", {
        state: {
          from: location.pathname,
        },
        replace: true,
      });
    } else if (jwtSelect && !isExpired) {
      dispatch(creditApplicationActions.fetchCreditApplication());
    }
  }, [jwtSelect, isExpired, jwtParam]);

  return <ErrorBoundary>{props.children}</ErrorBoundary>;
}

export default InvitationGuard;
