import { AddressDbType } from "@pd/redux/types/dbTypes";

export type AddressReqType = {
  line1: string;
  line2: string;
  city: string;
  state: string;
  postalCode: string;
};

export function addressDbToAddressRequest(
  addressDb: AddressDbType,
): AddressReqType {
  return {
    line1: addressDb.line1,
    line2: addressDb.line2,
    city: addressDb.city,
    state: addressDb.state,
    postalCode: addressDb.postalCode,
  };
}

export function addressRequestToAddressDb(
  addressReq: AddressReqType,
): AddressDbType {
  return {
    line1: addressReq.line1,
    line2: addressReq.line2,
    city: addressReq.city,
    state: addressReq.state,
    postalCode: addressReq.postalCode,
    country: "USA",
  };
}
