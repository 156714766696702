/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
import { createSelector } from "@reduxjs/toolkit";
import { isBuyerCredit } from "..";

export const selectNextRoute = createSelector(
  (state: any) => state,
  (state: any): string => {
    if (isBuyerCredit(state)) {
      return state.buyerCredit.routes.nextRoute;
    }
    return "";
  },
);
