import { ErrorPayload } from "@pd/api/utils/safeFetch";
import { AsyncStateType } from "@pd/redux/types";
import {
  PlaidLinkError,
  PlaidLinkOnEventMetadata,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccessMetadata,
} from "react-plaid-link";

export type {
  PlaidLinkError,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnEventMetadata,
} from "react-plaid-link";

// Define the individual types based on the provided image
export type LinkAccountType = {
  id: string;
  name: string;
  type: string;
  subtype: string;
  verificationStatus: string;
  mask: string;
};

export type LinkInstitutionType = {
  name: string;
  id: string;
};

export type LinkErrorType = {
  displayMessage: string;
  errorCode: string;
  errorMessage: string;
  errorType: string;
};

export type LinkedAcctType = {
  account: LinkAccountType;
  institution: LinkInstitutionType;
  error: LinkErrorType;
};

export type PlaidTokenStateType = AsyncStateType & {
  data: {
    linkToken: string;
    publicToken: string;
  };
};
export type PlaidLinkedAcctsStateType = AsyncStateType & {
  data: {
    accounts: LinkedAcctType[];
    linkAccountId: string;
  };
};
export type PlaidEventsStateType = {
  success: {
    publicToken: string;
    metadata: PlaidLinkOnSuccessMetadata;
  };
  exit: {
    error: PlaidLinkError | null;
    metadata: PlaidLinkOnExitMetadata;
  };
  event: {
    eventName: string;
    metadata: PlaidLinkOnEventMetadata;
  };
};

// Define the overall PlaidLinkStateType
export type PlaidLinkStateType = {
  tokens: PlaidTokenStateType;
  linkedAccounts: PlaidLinkedAcctsStateType;
  events: PlaidEventsStateType;
};

export function isPlaidLinkError(
  error: string | PlaidLinkError | ErrorPayload,
): error is PlaidLinkError {
  return (
    typeof error === "object" &&
    error !== null &&
    "error_type" in error &&
    "error_code" in error &&
    "error_message" in error &&
    "display_message" in error
  );
}
