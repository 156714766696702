import dayjs from "dayjs";
import { useState, useEffect, useCallback } from "react";
import {
  Modal,
  ButtonBase,
  Fade,
  Stack,
  Backdrop,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import {
  ArrowLeftOutlined,
  CloseOutlined,
  LoadingOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { modalStyles } from "../constants/styles";
import {
  FlowTypes,
  FlowSubTypes,
  NextFlowPropType,
} from "../../redux/types/modalTypes";
import modalActions from "../../redux/actions/modalActions";
import { selectPaymentDate } from "../../redux/selectors";

type Props = {
  onClose: () => void;
  onOpen: (
    caller: FlowTypes,
    actionTaken: FlowTypes | FlowSubTypes,
    props?: NextFlowPropType,
  ) => void;
  onConfirmPayment: () => void;
};
export default function PaymentReview(props: Props) {
  const dispatch = useAppDispatch();
  const paymentDate = useAppSelector(selectPaymentDate);
  const [open, setOpen] = useState(true);
  const [showLoading, setShowLoading] = useState(false);

  const handleSetPaymentDate = useCallback((date?: string) => {
    if (!date) {
      dispatch(modalActions.setPaymentDate(new Date().toISOString()));
    }
  }, []);

  const handleEditPaymentMethod = () => {
    props.onOpen(FlowTypes.paymentReview, FlowTypes.editPayMethod, {
      back: FlowTypes.paymentReview,
    });
  };

  const handleClose = () => {
    props.onClose();
    setOpen(false);
  };

  const handleConfirmPayment = () => {
    setShowLoading(true);
    props.onConfirmPayment();
    setTimeout(() => {
      setShowLoading(false);
      props.onOpen(FlowTypes.paymentReview, FlowSubTypes.next);
    }, 2000);
  };

  const handleOpenPayCalendar = () => {
    props.onOpen(FlowTypes.paymentReview, FlowSubTypes.pickDate, {
      back: FlowTypes.paymentReview,
    });
  };

  const handleBack = () => {
    props.onOpen(FlowTypes.paymentReview, FlowSubTypes.back);
  };

  useEffect(() => {
    if (!paymentDate) {
      handleSetPaymentDate();
    }
  }, [paymentDate]);

  if (showLoading) {
    return (
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { timeout: 500 } }}
      >
        <Fade in>
          <Stack gap={2} sx={modalStyles}>
            <Stack justifyContent="center" direction="row">
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", flexGrow: 1, textAlign: "center" }}
              >
                Processing Payment
              </Typography>
              <ButtonBase onClick={handleClose}>
                <CloseOutlined />
              </ButtonBase>
              <LoadingOutlined />
            </Stack>
            <Typography variant="subtitle1">
              Please wait while we process your payment
            </Typography>
          </Stack>
        </Fade>
      </Modal>
    );
  }

  const formattedPaymentDate = (() => {
    if (paymentDate === null) return <LoadingOutlined />;
    const payToday = new Date(paymentDate).getDate() === new Date().getDate();
    if (payToday) return "Today";
    return dayjs(paymentDate).format("MMM DD, YYYY");
  })();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{ backdrop: { timeout: 500 } }}
    >
      <Fade in>
        <Stack gap={2} sx={modalStyles} justifyContent="space-between">
          <Stack justifyContent="space-between" direction="row">
            <ButtonBase onClick={handleBack}>
              <ArrowLeftOutlined />
            </ButtonBase>
            <Typography variant="body1">Review Payment</Typography>
            <ButtonBase onClick={handleClose}>
              <CloseOutlined />
            </ButtonBase>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="subtitle1">Payment On</Typography>
            <ButtonBase onClick={handleOpenPayCalendar}>
              <Stack direction="row" alignItems="center" gap={2}>
                <Stack gap={1} alignItems="flex-end">
                  <Typography variant="body2">
                    {formattedPaymentDate}
                  </Typography>
                  {formattedPaymentDate === "Today" ? (
                    <Typography variant="subtitle2">
                      {dayjs(paymentDate).format("MMM DD, YYYY")}
                    </Typography>
                  ) : null}
                </Stack>
                <CalendarOutlined />
              </Stack>
            </ButtonBase>
          </Stack>

          <ButtonBase onClick={handleEditPaymentMethod}>
            Edit Pay Method
          </ButtonBase>
          <ButtonBase onClick={handleOpenPayCalendar}>
            Edit Payment Date
          </ButtonBase>
          <ButtonBase onClick={handleConfirmPayment}>Confirm</ButtonBase>
        </Stack>
      </Fade>
    </Modal>
  );
}
