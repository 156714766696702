import { Divider, Stack, Typography } from "@mui/material";
import { FadeInStack } from "@pd/components/FadeComponents";
import Footer from "@pd/layouts/BuyerCredit/components/Footer";
import { useAppSelector } from "@pd/redux/store";
import { selectCreditApplication } from "@pd/layouts/BuyerCredit/redux/selectors/creditApplication";
import { AddressDbType } from "@pd/redux/types/dbTypes";
import { CheckCircleOutline } from "@mui/icons-material";
import { selectMerchantName } from "@pd/layouts/BuyerCredit/redux/selectors/auth";
import CreditApplicationRow from "./components/CreditApplicationRow";

export default function Submitted() {
  const creditApplication = useAppSelector(selectCreditApplication);
  const merchantName = useAppSelector(selectMerchantName);

  const formatAddress = (addr: AddressDbType) => (
    <Stack direction="column">
      <Typography align="right">{addr.line1}</Typography>
      {addr.line2 ? <Typography align="right">{addr.line2}</Typography> : ""}
      <Typography align="right">
        {addr.city}, {addr.state} {addr.postalCode}
      </Typography>
    </Stack>
  );

  return (
    <FadeInStack
      spacing={3}
      sx={{
        height: "calc(100vh - 50px)",
        maxWidth: "600px",
      }}
    >
      <Stack
        spacing={3}
        sx={{
          flexGrow: 1,
        }}
      >
        <Typography variant="h5">You&apos;re all set</Typography>
        <Typography variant="h5">
          <span style={{ textTransform: "capitalize" }}>{merchantName}</span>{" "}
          will follow up on next steps and any request for additional documents
          or information.
        </Typography>

        <Stack
          direction="column"
          divider={<Divider orientation="horizontal" />}
        >
          <CreditApplicationRow
            label="Company"
            content={
              <Typography>{creditApplication.data.businessName}</Typography>
            }
          />
          <CreditApplicationRow
            label="EIN"
            content={<Typography>{creditApplication.data.ein}</Typography>}
          />
          <CreditApplicationRow
            label="Website"
            content={<Typography>{creditApplication.data.website}</Typography>}
          />
          <CreditApplicationRow
            label="Address"
            content={
              <Typography>
                {formatAddress(creditApplication.data.address)}
              </Typography>
            }
          />
          <CreditApplicationRow
            label="Bank"
            content={
              creditApplication.data.isBankLinked ? (
                <Stack direction="row" spacing={1}>
                  <Typography>Linked</Typography>
                  <CheckCircleOutline fontSize="small" />
                </Stack>
              ) : (
                <Typography>- - -</Typography>
              )
            }
          />
        </Stack>
      </Stack>
      <Footer>
        <div />
      </Footer>
    </FadeInStack>
  );
}
