import { Stack, Typography } from "@mui/material";
import { FadeInStack } from "@pd/components/FadeComponents";
import Footer from "@pd/layouts/BuyerCredit/components/Footer";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@pd/redux/store";
import creditApplicationActions from "@pd/layouts/BuyerCredit/redux/actions/creditApplication";
import { selectMerchantName } from "@pd/layouts/BuyerCredit/redux/selectors/auth";
import StitchAsyncButton from "@pd/components/StitchAsyncButton";

type Props = {
  onClickPlaid: () => void;
  handleClose: () => void;
};

export default function AppIncompleteModal(props: Props) {
  const dispatch = useDispatch();

  const merchantName = useAppSelector(selectMerchantName);

  const onNotNow = () => {
    dispatch(creditApplicationActions.setIsBankLinked(false));
    props.handleClose();
  };

  return (
    <FadeInStack
      spacing={3}
      sx={{
        maxWidth: "600px",
        minHeight: "400px",
      }}
    >
      <Stack
        spacing={3}
        sx={{
          flexGrow: 1,
        }}
      >
        <Typography variant="h5">Your review</Typography>
        <Typography variant="h5">
          Are you able to link your bank account to complete a review of your
          transaction history?
        </Typography>
      </Stack>
      <Footer>
        <Stack spacing={3}>
          <Stack>
            <Typography variant="body1" color="gray">
              <span style={{ textTransform: "capitalize" }}>
                {merchantName}
              </span>{" "}
              will follow up on next steps if you are unable to link your bank
              account and may request a manual review of bank statements.
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <StitchAsyncButton
              buttonText="Not now"
              variant={"outlined"}
              color={"inherit"}
              logoColor={"black"}
              loadingSize={"small"}
              loading={false}
              loadingPosition={{
                top: 0,
                left: 0,
              }}
              success={false}
              onClick={onNotNow}
              style={{
                marginRight: "10px",
              }}
            />
            <StitchAsyncButton
              buttonText="Yes"
              variant={"contained"}
              color={"primary"}
              logoColor={"black"}
              loadingSize={"small"}
              loading={false}
              loadingPosition={{
                top: 0,
                left: 0,
              }}
              success={false}
              onClick={props.onClickPlaid}
              style={{
                marginRight: "10px",
              }}
            />
          </Stack>
        </Stack>
      </Footer>
    </FadeInStack>
  );
}
