import safeFetch, { ErrorResType } from "@pd/api/utils/safeFetch";
import { endpoints } from "@pd/config/envSetup";
import { AddressReqType } from "@pd/utils/address";
import camelCaseToSnakeCaseKeys from "@pd/utils/camelCaseToSnakeCaseKeys";

export type CreditApplicationDBType = {
  appCompanyName: string;
  appTaxId: string;
  appWebsite: string;
  appAddress: AddressReqType;
  appPhone?: string;
  appEmail?: string;
};

export type CreditApplicationResType = {
  data: {
    companyName: string;
    taxId: string;
    website: string;
    address: AddressReqType;
    status: string;
  };
};

export type GetCreditApplicationResType =
  | CreditApplicationResType
  | ErrorResType;

export type UpdateCreditApplicationReqType = CreditApplicationDBType;

export type UpdateCreditApplicationResType =
  | CreditApplicationResType
  | ErrorResType;

export type SubmitCreditApplicationReqType = {
  publicToken?: string;
  ids?: string[];
};

export type SubmitCreditApplicationResType =
  | CreditApplicationResType
  | ErrorResType;

export const fetchGetCreditApplication = async (
  jwt: string,
): Promise<GetCreditApplicationResType> => {
  const url = `${endpoints.stitchBackend}/portal/credit/application?auth=${jwt}`;
  const response = await safeFetch(url, {
    method: "GET",
  });
  if ("error" in response) {
    return response;
  }
  const result = { ...response.data };
  return { data: result };
};

export const fetchUpdateCreditApplication = async (
  jwt: string,
  updateInfo: UpdateCreditApplicationReqType,
): Promise<UpdateCreditApplicationResType> => {
  const url = `${endpoints.stitchBackend}/portal/credit/application?auth=${jwt}`;
  const response = await safeFetch(url, {
    method: "PUT",
    body: JSON.stringify(camelCaseToSnakeCaseKeys(updateInfo)),
  });
  if ("error" in response) {
    return response;
  }
  const result = { ...response.data };
  return { data: result };
};

export const fetchSubmitCreditApplication = async (
  jwt: string,
  submitInfo: SubmitCreditApplicationReqType,
): Promise<SubmitCreditApplicationResType> => {
  const url = `${endpoints.stitchBackend}/portal/credit/application?auth=${jwt}`;
  const response = await safeFetch(url, {
    method: "POST",
    body: JSON.stringify(camelCaseToSnakeCaseKeys(submitInfo)),
  });
  if ("error" in response) {
    return response;
  }
  const result = { ...response.data };
  return { data: result };
};
