import usePlaid from "@pd/components/PlaidLink/hooks/usePlaid";
import {
  selectPlaidLinkExit,
  selectPlaidLinkSuccess,
} from "@pd/components/PlaidLink/redux/selectors";
import { useAppSelector } from "@pd/redux/store";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import pla from "@pd/components/PlaidLink/redux/actions";

type Props = {
  showModal: boolean;
  onSuccess: (publicToken: string, accountIds: string[]) => void;
  onExit: () => void;
  onRemount: () => void;
};

export default function PlaidLinkManager(props: Props) {
  const dispatch = useDispatch();
  const { startPlaidLink } = usePlaid();

  const plaidLinkSuccess = useAppSelector(selectPlaidLinkSuccess);
  const plaidLinkExit = useAppSelector(selectPlaidLinkExit);

  useEffect(() => {
    // Not cleared or initial state.
    if (plaidLinkSuccess.metadata.link_session_id) {
      props.onSuccess(
        plaidLinkSuccess.publicToken,
        plaidLinkSuccess.metadata.accounts.map((acc) => acc.id),
      );
      props.onRemount();
      dispatch(pla.events.clearPlaidLinkSuccess());
    }
  }, [plaidLinkSuccess]);

  useEffect(() => {
    // Not cleared or initial state.
    if (plaidLinkExit.metadata.link_session_id) {
      props.onExit();
      props.onRemount();
      dispatch(pla.events.clearPlaidLinkExit());
    }
  }, [plaidLinkExit]);

  useEffect(() => {
    if (props.showModal) {
      startPlaidLink();
    }
  }, [props.showModal]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}
